import React from "react";
import "./style.scss";

//icons
import { IconContext } from "react-icons";
import { HiOutlineExternalLink } from "react-icons/hi";

class AbCvImgDesc extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <>
            <IconContext.Provider value={{ color:"black", style:{marginTop:"-4px"} }}>
                <img src={this.props.imgUrl} srcSet={this.props.imgUrl + " 300w," + this.props.imgUrl + " 500w"} className="img-fluid mb-3" alt={this.props.altDesc} />
                <p className="font-weight-bold mb-0">{this.props.title} <a href={this.props.extLink} target="_blank" rel="noopener"><HiOutlineExternalLink /></a></p>
                <p className="font-grey font-size-less1 mb-0">{this.props.desc}</p>
            </IconContext.Provider>
            </>
        )
    }
}

export default AbCvImgDesc