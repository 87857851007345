import React from "react";
import { Link } from "gatsby";

//components
import Layout from "../components/layout";
import AbReveal from "../components/abReveal/abReveal";
import AbWatermark from "../components/abWatermark/abWatermark";
import CookiePopup from "../components/cookiePopup/cookiePopup";
import AbCvExperience from "../components/abCvExperience/abCvExperience";
import AbSkillTree from "../components/charts/abSkillTree/abSkillTree";
import AbCvImgDesc from "../components/abCvImgDesc/abCvImgDesc";

//icons
import { IconContext } from "react-icons";
import { 
  FaRegEnvelope, FaSkype, FaLinkedinIn,
  FaWaveSquare,  FaDribbble, FaGithub
} from "react-icons/fa";
import { HiOutlineExternalLink } from "react-icons/hi";

export default function Home() {
  return (
    <Layout>
      <AbReveal />
      <CookiePopup />
      <AbWatermark />

      {/* Header */}
      <div className="container pt-5">
        <div className="row">
          <div className="col">
            <Link to="/"><img src="/assets/imgs/logo-ab.svg" alt="Logo Andrea Balbo" width="50"/></Link>
          </div>
        </div>
      </div>

      {/* Welcome */}
      <div className="container-fluid pt-5 pb-5 font-size-more2"> 
        <div className="row">
          <div className="col-1 col-md-2"></div>
          <div className="col-3 col-md-2">
            <p className="font-weight-bold">WELCOME</p>
          </div>
          <div className="col-7 col-md-6">
            <p>I am Andrea Balbo, a multi-disciplinary developer currently working as Business Intelligence Specialist at <a className="dark-link" href="https://www.nh-hotels.com" target="_blank" rel="noopener">NH Hotel Group part of Minor</a>.</p>
            <p className="mb-5">I am also working as a freelance Full Stack Data Scientist, and I am specialized in new and upcoming digital technologies and their interconnection.</p>
            <p>Want to talk?</p>
            <div className="row">
              <div className="col">
                <IconContext.Provider value={{color: "white"}}>
                    <a href="mailto:info@andreabalbo.com"><FaRegEnvelope /></a>
                    <a href="skype:andre9480?chat" className="ml-3 ml-md-5"><FaSkype /></a>
                    <a className="ml-3 ml-md-5" href="https://www.linkedin.com/in/andreabalbo/" target="_blank" rel="noopener"><FaLinkedinIn /></a>
                    <a className="ml-3 ml-md-5" href="https://lottiefiles.com/andre94" target="_blank" rel="noopener"><FaWaveSquare /></a>
                    <a className="ml-3 ml-md-5" href="https://dribbble.com/andre94" target="_blank" rel="noopener"><FaDribbble /></a>
                    <a className="ml-3 ml-md-5" href="https://github.com/andre94" target="_blank" rel="noopener"><FaGithub /></a>
                </IconContext.Provider>
                <p className="mb-5"></p>
              </div>
          </div>      
          </div>
          <div className="col-1 col-md-2"></div>
        </div>
      </div>

      {/* CV */}
      <div className="container-fluid pt-5 pb-5" style={{backgroundColor:"#f9f9f9", color:"#000"}}>
        
        <div className="row">
          <div className="col-1 col-md-2"></div>
          <div className="col-10 col-md-8">
            <p className="font-montserrat dark-font-outline font-size-more9 mb-0 d-inline">Everything</p>
            <p className="font-montserrat font-size-more9 font-weight-bold mb-0 ml-2 d-inline">About me</p>
          </div>
          <div className="col-1 col-md-2"></div>
        </div>

        <div className="row mt-5">
          <div className="col-1 col-md-2"></div>
          <div className="col-3 col-md-2">
            <p className="font-weight-bold font-size-more2">IN BRIEF</p>
          </div>
          <div className="col-7 col-md-6">
            <div className="row">
              <div className="col-12 col-md-6">
                <p className="text-justify">Master in Big Data and Business Analytics, Bachelor's Degree in Statistical and Economic Sciences. Passionate in Big Data, Statistics and Computer Sciences.</p>
                <p className="text-justify">As generalizing specialist, I contribute with direct value to the team, having a vaste knowledge of software development and the business domain. I aim to ensuring excellent results and successful delivery of solutions and products.</p>
              </div>
              <div className="col-12 col-md-6">
              <p className="text-justify">My passion is actively seeking to gain new skills in both my existing specialties and other areas, including both technical and domain ones.</p>
                <p className="text-justify">Curious since birth, I am looking for new and innovative ideas to bring to a project. I believe in the creation of products and brands that make our lives better, easier and more fun.</p>
              </div>
            </div>            
          </div>
          <div className="col-1 col-md-2"></div>
        </div>

        <div className="row mt-5">
          <div className="col-1 col-md-2"></div>
          <div className="col-3 col-md-2">
            <p className="font-weight-bold font-size-more2">EXPERIENCE</p>
          </div>
          <div className="col-7 col-md-6">
            <div className="row">
              <div className="col-12 col-md-6">
                <AbCvExperience 
                  title="Business Intelligence Specialist"
                  company="NH Hotel Group part of Minor"
                  companyUrl="https://www.nh-hotels.com/"
                  place="Headquarters, Madrid"
                  period="Jul, 2019 - Current"
                  desc="Team leader of global projects to help the company success at reaching its main objectives of profitability. I am in charge of the development of innovative and advanced systems for the analysis, in orderd to facilitate the decision making and the implementation of strategic actions by the different stakeholders. This includes definition, modeling, programming, analysis, testing, delivery and follow-up activities, as well as others." 
                />
              </div>
              <div className="col-12 col-md-6">
                <AbCvExperience 
                  title="Full Stack Data Scientist"
                  company="andreabalbo.com"
                  companyUrl="https://www.andreabalbo.com/"
                  place="Milan & Madrid"
                  period="Jan, 2012 - Current"
                  desc="As Full Stack Data Scientist I am proficient in both the front-end and back-end languages and frameworks, as well as in Big Data, Statistics, Cloud Computing, Visualisation and Network environments. My greatest passion is continuos learning, above all development and upcoming digital technologies and their interconnection. I am also well-versed in both business logic and user experience, to guide and consult on strategy too. Take a look at my Skill Tree here below." 
                />
              </div>
            </div>            
          </div>
          <div className="col-1 col-md-2"></div>
        </div>

        <div className="row">
          <div className="col-1 col-md-2"></div>
          <div className="col-3 col-md-2"></div>
          <div className="col-7 col-md-6">
            <div className="row">
              <div className="col">
                <AbSkillTree />
              </div>
            </div>            
          </div>
          <div className="col-1 col-md-2"></div>
        </div>

        <div className="row mt-5">
          <div className="col-1 col-md-2"></div>
          <div className="col-3 col-md-2">
          </div>
          <div className="col-7 col-md-6">
            <div className="row">
              <div className="col-12 col-md-6">
                <AbCvExperience 
                  title="Business Support Analyst (EMEA)"
                  company="Associated Foreign Exchange Ireland Ltd"
                  companyUrl="https://www.afex.com/"
                  place="Branch, Madrid"
                  period="Nov, 2017 - Jul, 2019"
                  desc="At european level, front and back office, query, credit, compliance and accountability activities, including improvement and implementation of process, and international wire transfer investigations." 
                />
              </div>
              <div className="col-12 col-md-6">
              <AbCvExperience 
                  title="Assistant Account Executive"
                  company="GfK"
                  companyUrl="https://www.gfk.com/"
                  place="Milan"
                  period="Nov, 2015 - May, 2016"
                  desc="" 
                />
                <AbCvExperience 
                  title="Bookkeeper"
                  company="Ceass Rossi di Di Terlizzi Anna Maria"
                  companyUrl="https://www.ceassrossi.com/"
                  place="Milan"
                  period="Sep, 2013 - Sep, 2017"
                  desc="" 
                />
              </div>
            </div>            
          </div>
          <div className="col-1 col-md-2"></div>
        </div>

        <div className="row">
          <div className="col-1 col-md-2"></div>
          <div className="col-3 col-md-2">
            <p className="font-weight-bold font-size-more2">STUDIES</p>
          </div>
          <div className="col-7 col-md-6">
            <div className="row">
              <div className="col-12 col-md-4">
                <img src="/assets/imgs/logo-imf.webp" srcSet="/assets/imgs/logo-imf_sm.webp 300w, /assets/imgs/logo-imf_md.webp 500w" height="50" className="mx-auto d-block" alt="Imf Business School" />
                <IconContext.Provider value={{ color:"black", style:{marginTop:"-4px"} }}>
                  <p className="mt-3">Master in Big Data and Business Analytics <a href="https://www.imf-formacion.com/masters-profesionales/master-big-data-business-intelligence/" target="_blank" rel="noopener"><HiOutlineExternalLink /></a></p>
                </IconContext.Provider>
              </div>
              <div className="col-12 col-md-4">
                <img src="/assets/imgs/logo-bicocca.webp" srcSet="/assets/imgs/logo-bicocca_sm.webp 300w" height="50" className="mx-auto d-block" alt="Università degli studi di Milano Bicocca" />
                <IconContext.Provider value={{ color:"black", style:{marginTop:"-4px"} }}>
                  <p className="mt-3">Bachelor's Degree in Statistical and Economic Sciences <a href="https://www.unimib.it/ugov/degree/5459/" target="_blank" rel="noopener"><HiOutlineExternalLink /></a></p>
                </IconContext.Provider>
              </div>
              <div className="col-12 col-md-4">
                <img src="/assets/imgs/logo-iisvittuone.webp" srcSet="/assets/imgs/logo-iisvittuone_sm.webp 300w" height="50" className="mx-auto d-block" alt="IIS Vittuone" />
                <IconContext.Provider value={{ color:"black", style:{marginTop:"-4px"} }}>
                  <p className="mt-3 mb-0">High School Diploma in Computer Systems <a href="https://www.iisvittuone.it/" target="_blank" rel="noopener"><HiOutlineExternalLink /></a></p>
                </IconContext.Provider>
              </div>
            </div>            
          </div>
          <div className="col-1 col-md-2"></div>
        </div>

      </div>

      {/* Websites */}
      <div className="container-fluid pt-5 pb-5" style={{backgroundColor:"#fff", color:"#000"}}>
        
        <div className="row">
          <div className="col-1"></div>
          <div className="col">
            <p className="font-montserrat dark-font-outline font-size-more9 mb-0 d-inline">Branding</p>
            <p className="font-montserrat font-size-more9 font-weight-bold mb-0 ml-2 d-inline">/ Websites /</p>
            <p className="font-montserrat dark-font-outline font-size-more9 mb-0 ml-2 d-inline">Projects</p>
          </div>
        </div>

        {/* Website - Pellet del sureste */}
        <div className="row justify-content-center mt-5">
          <div className="col-lg-1"></div>
          <div className="col-10 col-lg-4">
            <AbCvImgDesc
              imgUrl="/assets/imgs/website-pelletdelsureste.webp"
              altDesc="Pellet del Sureste Mockup"
              extLink="https://www.pelletdelsureste.com/"
              title="Pellet del Sureste"
              desc="Freelance Project"
            />
          </div>
          <div className="col-10 col-lg-4 mt-3 mt-lg-0">
            <div className="embed-responsive embed-responsive-4by3">
              <iframe className="embed-responsive-item" src="https://player.vimeo.com/video/450474352?autoplay=1&loop=1&autopause=0&background=1&mute=0" title="Video Pellet del Sureste Mockup" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
          </div>
          <div className="col-10 col-lg-2 mt-3 mt-lg-0">
            <p className="font-size-less2">Co-founder of Pellet del Sureste company, I took care of the ideation and implementation of the corporate image which, thanks to a multi-platform communication line, has led to the brand awareness. I develop marketing campaigns using both traditional and online channels, monitoring key performance indicators (KPIs), areas of improvement and positioning against the competition.</p>
          </div>
          <div className="col-lg-1"></div>
        </div>

        {/* Website - Ceass Rossi */}
        <div className="row justify-content-center mt-5">
          <div className="col-lg-1"></div>
          <div className="col-10 col-lg-4">
            <AbCvImgDesc
              imgUrl="/assets/imgs/website-ceassrossi.webp"
              altDesc="Ceass Rossi Mockup"
              extLink="https://www.ceassrossi.com/"
              title="Ceass Rossi"
              desc="Freelance Project"
            />
          </div>
          <div className="col-10 col-lg-2 mt-3 mt-lg-0">
            <img src="/assets/imgs/website-ceassrossi-gestionale2.webp" srcSet="/assets/imgs/website-ceassrossi-gestionale2_sm.webp 300w, /assets/imgs/website-ceassrossi-gestionale2_md.webp 500w" className="img-fluid" alt="Gestionale 2.0" />
            <img src="/assets/imgs/website-ceassrossi-gestionale1.webp" className="mt-3 img-fluid" alt="Gestionale 2.0" />
          </div>
          <div className="col-10 col-lg-4 mt-3 mt-lg-0">
            <p className="font-size-less2">Tailor-made management software for managing bills, transport documents, customers, store, accounting and corporate statistics.</p>
            <p className="font-size-less2">Based entirely on web technology, it exploits both the power of the front-end, for the creation of dynamic and immediate layouts, and the power of the back-end, to organize all the information in a structured way through the use of databases.</p>
            <p className="font-size-less2">It essentially replicate the functions of existing online applications but the advantage of this software is the ad-hoc customization for the company and the possibility of offline use.</p>
          </div>
          <div className="col-lg-1"></div>
        </div>

        {/* Project - Kintroller */}
        <div className="row justify-content-center mt-5">
          <div className="col-lg-1"></div>
          <div className="col-10 col-lg-4">
            <AbCvImgDesc
              imgUrl="/assets/imgs/project-kintroller.webp"
              altDesc="Kintroller Mockup"
              extLink="https://www.andreabalbo.com/"
              title="Kintroller"
              desc="Freelance Project"
            />
            <p className="font-size-less2 mt-3">During my studies I had the opportunity to create "Kintroller", to help people affected by agoraphobia. These people experience a feeling of fear or severe discomfort when they are in an unfamiliar environment.</p>
            <p className="font-size-less2 mt-3">The main tool used is Microsoft's Kinect sensor which, in combination with a server and client, allows you to navigate, with the movements of your body, through the landscapes that Google provides with its StreetView service.</p>
          </div>
          <div className="col-10 col-lg-4 mt-3 mt-lg-0">
            <AbCvImgDesc
              imgUrl="/assets/imgs/website-martina.webp"
              altDesc="Martina Vimercati Mockup"
              extLink="https://www.martinavimercati.it/"
              title="Martina Vimercati Psychologist"
              desc="Freelance Project"
            />
          </div>
          <div className="col-10 col-lg-2 mt-3 mt-lg-0">
            <figure>
              <blockquote className="blockquote font-size-less2">
                <p>There are many things you have learned without knowing it. And a lot of the knowledge that you thought was important on a conscious level has slipped into your unconscious mind.</p>
              </blockquote>
              <figcaption className="blockquote-footer">
                M. H. Erikson
              </figcaption>
            </figure>
            <img src="/assets/imgs/website-martina-seduta.webp" srcSet="/assets/imgs/website-martina-seduta_sm.webp 300w, /assets/imgs/website-martina-seduta_md.webp 500w" className="img-fluid mt-4 d-none d-lg-block" alt="Seduta Psicologa" />
          </div>
          <div className="col-lg-1"></div>
        </div>
        
      </div>

      {/* References */}
      <div className="container-fluid pt-5 pb-5" style={{backgroundColor:"#f9f9f9", color:"#000"}}>
        
        <div className="row">
          <div className="col-1"></div>
          <div className="col">
            <p className="font-montserrat dark-font-outline font-size-more9 mb-0 d-inline">Professional</p>
            <p className="font-montserrat font-size-more9 font-weight-bold mb-0 ml-2 d-inline">References</p>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-1"></div>
          <div className="col-10 col-lg-5">
            <div className="card">
              <div className="card-body">
                <blockquote className="blockquote mb-0 font-size-less1">
                  <p>I had the pleasure to work with Andrea during our experience in AFEX. He is a brilliant young professional with an excellent analytical mind, with impressive abilities in statistics, coding and machine learning. Thanks to him, our team was able to complete projects and tasks at an astonishing speed as he tremendously helped the team with his skills. His interest and deep knowledge of technology were crucial to the team. I would recommend Andrea to anyone who is looking for an incredibly fast learner with a problem-solving-mind-set to complete complex projects with complicated architectures. Glad to have worked with him! He is a great source of knowledge and I learnt a lot from him!</p>
                  <footer className="blockquote-footer"><strong>M. B.</strong> Sr. Pre-Sales & Implementation Consultant at Western Union Business Solutions</footer>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="col-10 col-lg-5 mt-3 mt-lg-0">
          <div className="card">
              <div className="card-body">
                <blockquote className="blockquote mb-0 font-size-less1">
                  <p>I have had the great fortune to meet Andrea in my team. I am more than satisfied about his job performance. Andrea is a great colleague, young, willing to learn and improve, ready to help the team. He has impressive IT skills at 360°. Often he helped me with brilliant and smart solution to make our work easier! He grew a lot during the time he was with us and this makes me proud to have had him on my team. If there was the opportunity, I would immediately take him back into the team. This is to highlight the esteem and trust he has been able to win.</p>
                  <footer className="blockquote-footer"><strong>M. L.</strong> EMEA Business Support Manager at Associated Foreign Exchange Ireland Ltd</footer>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>

      {/* Footer */}
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col">
            <Link to="/"><img src="/assets/imgs/logo-ab.svg" alt="Logo Andrea Balbo" width="50"/></Link>
            <p className="d-inline ml-3">Designed & Built by Andrea Balbo &copy; {new Date().getFullYear()} <Link to="/privacy-policy" className="dark-link">/ Privacy Policy</Link></p>
          </div>
        </div>
      </div>

    </Layout>
  );
}
