import React from "react";
import "./style.scss";

//icons
import { IconContext } from "react-icons";
import { HiOutlineExternalLink } from "react-icons/hi";

class AbCvExperience extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <>
            <IconContext.Provider value={{ color:"black", style:{marginTop:"-4px"} }}>
                <div className="abCvExperience-parent d-flex flex-column">
                    <span className="mb-3"></span>
                    <p className="font-size-more1 font-weight-bold mb-1">{this.props.title}</p>
                    <p className="mb-0">{this.props.company} <a href={this.props.companyUrl} target="_blank" rel="noopener"><HiOutlineExternalLink /></a></p>
                    <p className="mb-1 font-size-less1">{this.props.place}</p>
                    <p className="mb-2 font-size-less1 text-right">{this.props.period}</p>
                    <p className="mb-0 text-justify">{this.props.desc}</p>
                    <span className="mb-5"></span>
                </div>
            </IconContext.Provider>
            </>
        )
    }
}

export default AbCvExperience